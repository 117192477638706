import * as Sentry from '@sentry/react';
import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { App, AuthenticatedUser } from './app.modal';
import {
  activateCommunity,
  getApps,
  getEmbedrockKey,
  loadConfig,
  loginUser,
  logoutUser,
  registerNotificationToken,
  setActiveApp,
  setSingleAppConfig,
} from './app.thunk';
import { convertUserResponse } from './app.factory';

export const appAdapter = createEntityAdapter<App>({
  selectId: (app: App) => app.key,
  sortComparer: (a, b) => a.key.localeCompare(b.key),
});

const getInitialState = (): AuthenticatedUser => ({
  version: process.env.REACT_APP_VERSION || '0.0.0',
  email: '',
  auth: {
    appToken: undefined,
    appRefreshToken: undefined,
    appTokenExpiry: undefined,
    authToken: undefined,
    refreshToken: undefined,
    tokenExpiry: undefined,
    deviceId: undefined,
    uuid: undefined,
    embedrockKey: undefined,
    notificationToken: undefined,
  },
  user: undefined,
  conditions: [],
  activeCommunity: undefined,
  selectedApp: undefined,
  recentApps: [],
  apps: appAdapter.getInitialState(),
  appInfo: undefined,
  isGroupManagementEnabled: false,
  isAeDetectionEnabled: false,
  isCMSEnabled: false,
});

const userSlice = createSlice({
  name: 'app',
  initialState: getInitialState(),
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(loadConfig.fulfilled, (state, action) => {
        return { ...state, ...action.payload };
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        const { loginData, appInfo } = action.payload;
        Sentry.setUser({ email: loginData.user.email });
        return convertUserResponse(loginData.user, appInfo, state);
      })
      .addCase(logoutUser.fulfilled, () => {
        return getInitialState();
      })
      .addCase(activateCommunity.fulfilled, (state, action) => {
        const { communityKey } = action.payload;
        const activeCommunity = state.conditions.find(
          (community) => community.key === communityKey,
        );

        Sentry.setTag('community', activeCommunity?.key);

        return { ...state, activeCommunity };
      })
      .addCase(getApps.fulfilled, (state, action) => {
        appAdapter.setAll(state.apps, action.payload);
      })
      .addCase(setActiveApp.fulfilled, (state, action) => {
        const { authData, activeApp } = action.payload;

        const { recentApps } = state;

        Sentry.setTag('app', activeApp?.key);

        return {
          ...state,
          selectedApp: activeApp,
          recentApps: [
            activeApp?.key || '',
            ...recentApps.filter((app) => app !== activeApp?.key),
          ].slice(0, 3),
          auth: {
            appToken: authData.token,
            appRefreshToken: authData.encrypted_refresh,
            appTokenExpiry: authData.exp,
          },
        };
      })
      .addCase(getEmbedrockKey.fulfilled, (state, action) => {
        return {
          ...state,
          auth: { ...state.auth, embedrockKey: action.payload.Key },
        };
      })
      .addCase(setSingleAppConfig.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          selectedApp: payload.activeApp as App,
          auth: {
            appToken: payload.authData.token,
            appRefreshToken: payload.authData.encrypted_refresh,
            appTokenExpiry: payload.authData.exp,
          },
        };
      })
      .addCase(registerNotificationToken.fulfilled, (state, action) => {
        return {
          ...state,
          auth: {
            ...state.auth,
            notificationToken: action.meta.arg.notificationToken,
          },
        };
      }),
});

export default {
  reducers: userSlice.reducer,
  appAdapter,
  getInitialState,
};
