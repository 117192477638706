// @flow
import * as React from 'react';
import { useSelector } from 'react-redux';
import { EntityId } from '@reduxjs/toolkit';
import OvalTabs from '@shared/components/tabs/OvalTabs';
import { ChatSelector } from '@data/chat/Chat.selector';
import useAppDispatch from '@data/useAppDispatch';
import { fetchConversation } from '@data/chat/Chat.thunk';
import { ReactComponent as NoResults } from '@assets/images/no_results.svg';
import ConversationItem from './components/ConversationItem';
import Header from './components/Header';

type ConversationListProps = {
  selectedConversation: EntityId | null;
  selectConversation: (conversationId: EntityId | null) => void;
  setSelectedConversation: (id: EntityId | null) => void;
};

const ConversationList: React.FC<ConversationListProps> = ({
  selectConversation,
  selectedConversation,
  setSelectedConversation,
}) => {
  const tabs = ['All', 'Unread', 'Read'];
  const [selectedTab, setSelectedTab] = React.useState(tabs[0]);
  const [searchedConversation, setSearchedConversation] = React.useState('');
  const filteredConversations = useSelector((state: any) =>
    ChatSelector.conversations.selectFilteredConversations(state, {
      search: searchedConversation,
      selectedTab,
    }),
  );
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    dispatch(fetchConversation());
  }, [selectedTab, selectedConversation, dispatch]);

  const [registerTimeout, setRegisterTimeout] = React.useState<number | null>(
    null,
  );

  React.useEffect(() => {
    if (!registerTimeout) {
      const id = setInterval(
        () => {
          dispatch(fetchConversation());
        },
        10000,
        'conversation',
      );
      setRegisterTimeout(id);
    }
    return () => {
      clearInterval(registerTimeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [registerTimeout]);

  const renderConversations = () => {
    if (filteredConversations.length === 0)
      return (
        <div className="flex flex-col items-center w-full h-full">
          <h6 className="w-full mt-5 text-center h6 text-on-primary">
            0 Results
          </h6>
          <NoResults className="w-[115px] h-[115px] mt-[50px]" />
        </div>
      );
    return filteredConversations.map((conversationId) => (
      <ConversationItem
        key={`conversation-${conversationId}`}
        onClick={() => selectConversation(conversationId)}
        selected={selectedConversation === conversationId}
        conversationId={String(conversationId)}
      />
    ));
  };

  const startNewConversation = () => {
    setSelectedConversation(null);
  };

  return (
    <div className="flex flex-col w-full h-full">
      <Header
        onSearch={setSearchedConversation}
        startNewConversation={startNewConversation}
      />
      <OvalTabs
        options={tabs}
        selectedOption={selectedTab}
        onChange={setSelectedTab}
      />
      <div className="flex-1 w-full overflow-auto">{renderConversations()}</div>
    </div>
  );
};
export default ConversationList;
