import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import { SvgIcon } from '@mui/material';
import React, { FunctionComponent, SVGProps } from 'react';
import { useNavigate } from 'react-router-dom';
import { defaultAvatar } from '@shared/components/avatar/Avatar';

export interface NavItemProps {
  pathname?: string;
  to?: string;
  icon?: FunctionComponent<
    SVGProps<SVGSVGElement> & { title?: string | undefined }
  > | null;
  label: string;
  onClick?: () => void;
  image?: string;
  isHighlighted?: boolean;
}

/**
 * @todo the code here is very messy, clean up!
 */
const NavItem: React.FC<NavItemProps> = ({
  pathname,
  label,
  to,
  icon,
  onClick,
  image,
  isHighlighted,
}) => {
  const isActive = pathname === to;

  const activeIndicatorClass = `${
    isActive
      ? ' block absolute h-full w-1 bg-brand-primary rounded-r-3xl top-0 -left-3'
      : 'hidden'
  }`;
  const iconButtonClass =
    'block !p-0 w-full h-full  bg-transparent text-brand-primary !fill-brand-primary';

  const navigate = useNavigate();

  const handleOnItemClick = () => () => {
    if (onClick) onClick();
    else navigate(to || '#');
  };

  return (
    <Tooltip
      enterDelay={50}
      classes={{ tooltip: '!bg-black', arrow: '!color-[#000]' }}
      className="hidden lg:block"
      title={label}
      arrow
      placement="right"
    >
      <li
        role="presentation"
        key={`item-${label}`}
        className="relative block ml-3 mr-3 w-[54px] h-[54px] mt-[14px] mb-[2px]"
        onClick={onClick}
      >
        <div className={activeIndicatorClass} />
        <IconButton
          onClick={handleOnItemClick()}
          classes={{
            root: '!border !border-[#EBECEF] border-solid relative',
          }}
          className={iconButtonClass}
        >
          <div
            style={{ backgroundColor: isHighlighted ? 'red' : 'transparent' }}
            className="absolute w-3 h-3  rounded-full top-0 right-0"
          />
          {icon ? (
            <SvgIcon className="w-full h-full p-3" component={icon} />
          ) : (
            <img className="rounded-full" src={image || defaultAvatar} alt="" />
          )}
        </IconButton>
      </li>
    </Tooltip>
  );
};

NavItem.defaultProps = {
  pathname: '/',
  to: '/',
  icon: null,
  onClick: undefined,
  image: undefined,
};

export default NavItem;
