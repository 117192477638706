import { DiscoverPage, HomePage } from '@view/index';

import ErrorView from '@view/error-page/ErrorView';
import Endpoints from '@view/routes/endpoints';
import CreateContent from '@view/create-content/CreateContent.lazy';
import ContentView from '@view/discover-page/components/ContentView';
import { RouteProps, RouteType } from './types';
import PostDetails from '../post-details/PostDetails';
import DraftedPosts from '../drafted-posts/DraftedPosts';
import MyProjects from '../content-management-system/ProjectList';
import ScheduledPosts from '../scheduled-posts/ScheduledPosts';
import BookmarkedPosts from '../bookmarked-posts/BookmarkedPosts';
import SupportGroups from '../support-group-list/SupportGroups';
import FlaggedPosts from '../flagged-posts/FlaggedPostsPage';
import AdverseEventsPage from '../adverse-events/AdverseEventsPage';
import ReviewedAdverseEventsPage
  from '../adverse-events/ReviewedAdverseEventsPage';
import SupportGroupRequestsView
  from '../support-group-requests/SupportGroupRequests';
import CreateEvent from '../create-event/CreateEvent';
import CommunitySelection from '../community-selection/CommunitySelection';
import SupportGroupDetails from '../support-group-details/SupportGroupDetails';
import SupportGroupActivities
  from '../support-group-activities/SupportGroupActivities';
import SocialEventDetails from '../social-event-details/SocialEventDetails';
import MyCircle from '../my-circle/MyCircle';
import ChatView from '../chat/ChatView';
import NotificationsView from '../notifications-view/NotificationsView';
import Setting from '../setting/Setting';
import AuthView from '../authentication-page/AuthView';
import AppSelection from '../app-selection/AppSelection';
import ContentPreview from '../content-preview/ContentPreview';
import ContentTypeSelection
  from '../content-type-selection/ContentTypeSelection';
import ProfileView from '../profile/ProfileView';
import { ErrorType } from '../../models/constants/error';
import { useSelector } from 'react-redux';
import { AppSelector } from '@data/app/app.selector';
import { useMemo } from 'react';

/**
 * @todo add Error View
 */

const useRoutes = (): RouteProps[] => {
  const isCMSActive = useSelector(AppSelector.selectIsCMSActive);
  const isAEDetectionActive = useSelector(
    AppSelector.selectIsAeDetectionActive,
  );
  const isGroupManagementActive = useSelector(
    AppSelector.selectIsGroupManagementActive,
  );

  return useMemo(() => {
    const commonRoutes: RouteProps[] = [
      {
        path: Endpoints.HOME,
        exact: true,
        Component: HomePage,
        type: RouteType.PROTECTED,
      },
      {
        path: Endpoints.SOCIAL_EVENT,
        exact: true,
        Component: SocialEventDetails,
        type: RouteType.PROTECTED,
      },
      {
        path: Endpoints.CREATE_EVENT,
        exact: true,
        Component: CreateEvent,
        type: RouteType.PROTECTED,
      },
      {
        path: Endpoints.EDIT_EVENT,
        exact: true,
        Component: CreateEvent,
        type: RouteType.PROTECTED,
      },
      {
        path: Endpoints.CIRCLE,
        exact: true,
        Component: MyCircle,
        type: RouteType.PROTECTED,
      },
      {
        path: Endpoints.CHAT,
        exact: true,
        Component: ChatView,
        type: RouteType.PROTECTED,
      },
      {
        path: Endpoints.PORTAL,
        Component: CommunitySelection,
        type: RouteType.PROTECTED,
      },
      {
        path: Endpoints.PROFILE,
        exact: true,
        Component: ProfileView,
        type: RouteType.PROTECTED,
      },
      {
        path: Endpoints.PROFILE_ID,
        exact: true,
        Component: ProfileView,
        type: RouteType.PROTECTED,
      },
      {
        path: Endpoints.BOOKMARKS,
        exact: true,
        Component: BookmarkedPosts,
        type: RouteType.PROTECTED,
      },
      {
        path: Endpoints.AUTH,
        Component: AuthView,
        type: RouteType.AUTH,
      },

      {
        path: Endpoints.POST,
        exact: true,
        Component: PostDetails,
        type: RouteType.PROTECTED,
      },
      {
        path: Endpoints.SCHEDULE_POST,
        exact: true,
        Component: ScheduledPosts,
        type: RouteType.PROTECTED,
      },
      {
        path: Endpoints.DRAFTED_POSTS,
        exact: true,
        Component: DraftedPosts,
        type: RouteType.PROTECTED,
      },
      {
        path: Endpoints.FLAGGED_POSTS,
        exact: true,
        Component: FlaggedPosts,
        type: RouteType.PROTECTED,
      },
      {
        path: Endpoints.NOTIFICATIONS,
        Component: NotificationsView,
        type: RouteType.PROTECTED,
      },
      {
        path: Endpoints.GROUPS,
        Component: SupportGroups,
        type: RouteType.PROTECTED,
      },
      {
        path: Endpoints.GROUP_DETAILS,
        Component: SupportGroupDetails,
        type: RouteType.PROTECTED,
      },
      {
        path: Endpoints.GROUP_ACTIVITY,
        Component: SupportGroupActivities,
        type: RouteType.PROTECTED,
      },
      {
        path: Endpoints.SETTINGS,
        exact: true,
        Component: Setting,
        type: RouteType.PROTECTED,
      },
      {
        path: Endpoints.DISCOVER,
        exact: true,
        Component: DiscoverPage,
        type: RouteType.PROTECTED,
      },
      {
        path: '*',
        Component: () =>
          ErrorView({
            type: ErrorType.NOT_FOUND,
          }),
        type: RouteType.PROTECTED,
      },
      {
        path: Endpoints.DISCOVER_VIEW,
        exact: true,
        Component: ContentView,
        type: RouteType.PROTECTED,
      },
    ];
    let routes = commonRoutes;
    if (isCMSActive) {
      routes = routes.concat([
        {
          path: Endpoints.DISCOVER_CREATE,
          exact: true,
          Component: CreateContent,
          type: RouteType.PROTECTED,
        },
        {
          path: Endpoints.DISCOVER_MY_PROJECTS,
          exact: true,
          Component: MyProjects,
          type: RouteType.PROTECTED,
        },
        {
          path: Endpoints.DISCOVER_EDIT,
          exact: true,
          Component: CreateContent,
          type: RouteType.PROTECTED,
        },
        {
          path: Endpoints.DISCOVER_CREATE_SELECTION,
          exact: true,
          Component: ContentTypeSelection,
          type: RouteType.PROTECTED,
        },
        {
          path: Endpoints.DISCOVER_PREVIEW,
          exact: true,
          Component: ContentPreview,
          type: RouteType.PROTECTED,
        },
      ]);
    }
    if (isAEDetectionActive) {
      routes = routes.concat([
        {
          path: Endpoints.ADVERSE_EVENTS_REVIEWED,
          Component: ReviewedAdverseEventsPage,
          type: RouteType.PROTECTED,
        },
        {
          path: Endpoints.ADVERSE_EVENTS,
          Component: AdverseEventsPage,
          type: RouteType.PROTECTED,
        },
      ]);
    }
    if (isGroupManagementActive) {
      routes.push({
        path: Endpoints.GROUP_REVIEWS,
        Component: SupportGroupRequestsView,
        type: RouteType.PROTECTED,
      });
    }

    if (process.env.REACT_APP_IS_SINGLE_APP !== 'true') {
      routes.push({
        path: Endpoints.APP_SELECTION,
        Component: AppSelection,
        type: RouteType.AUTH,
      });
    }
    return routes;
  }, [isCMSActive, isAEDetectionActive, isGroupManagementActive]);
};

export default useRoutes;
