import createAsyncThunkWithAuth from '@data/createAsyncThunkWithAuth';
import axios from 'axios';
import { ApiEndpoint } from '@utils/types';

export const fetchNotifications = createAsyncThunkWithAuth<void>(
  'notifications/fetchNotifications',
  async (args, urlGenerator) => {
    const [readNotifications, unreadNotifications] = await Promise.all([
      (
        await axios.get(
          urlGenerator(ApiEndpoint.NOTIFICATIONS_LIST, {
            user: args.userId,
            type: 'history',
            notificationType: 'all',
          }),
        )
      )?.data || [],
      (
        await axios.get(
          urlGenerator(ApiEndpoint.NOTIFICATIONS_LIST, {
            user: args.userId,
            type: 'new',
          }),
        )
      )?.data || [],
    ]);

    return { readNotifications, unreadNotifications };
  },
);

export const fetchNotificationsCount = createAsyncThunkWithAuth<void>(
  'notifications/fetchNotificationsCount',
  async (args, urlGenerator) => {
    const [{ new_count }] = await Promise.all([
      (
        await axios.get(
          urlGenerator(ApiEndpoint.NOTIFICATIONS_LIST, {
            user: args.userId,
            type: 'new',
            // notificationType: 'all',
          }),
        )
      )?.data || [],
    ]);

    return { count: new_count || 0 };
  },
);

export const markNotificationAsRead = createAsyncThunkWithAuth<{
  notificationId: string;
}>('notifications/markNotificationAsRead', async (args, urlGenerator) => {
  return new Promise((resolve, reject) => {
    axios
      .put(
        urlGenerator(ApiEndpoint.READ_NOTIFICATIONS, {
          user: args.userId,
          notification: args.notificationId,
        }),
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch(reject);
  });
});
