import { initializeApp } from 'firebase/app';
import { getMessaging, getToken } from 'firebase/messaging';

const firebaseConfig = {
  // apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  // authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  // databaseURL: process.env.REACT_APP_FIREBASE_DATABASE,
  // projectId: process.env.REACT_APP_FIREBASE_PROJECT,
  // storageBucket: process.env.REACT_APP_FIREBASE_STORAGE,
  // messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING,
  // appId: process.env.REACT_APP_FIREBASE_APP_ID,
  // measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT,
  apiKey: 'AIzaSyAnKOX0sv08YSPt1rIaYOUTubvpmc-xvgc',
  authDomain: 'curatio-debug.firebaseapp.com',
  projectId: 'curatio-debug',
  storageBucket: 'curatio-debug.firebasestorage.app',
  messagingSenderId: '1063675398595',
  appId: '1:1063675398595:web:a4624ecbe47696e5ee1576',
  measurementId: 'G-R5NBK8NCG0',
};

const app = initializeApp(firebaseConfig);

console.log(app.name);
export const messaging = getMessaging(app);
console.log(messaging);

getToken(messaging, {
  vapidKey:
    'BCdIujuxqsTQeDDj8wzvVdoCmETA-CZ7ZQKDXvMO_Uch0oaUqrONLUC8kTsAOuTQIYLwLjAlPtjnJr48KAisBbE',
})
  .then((currentToken) => {
    if (currentToken) {
      console.log('current token for client: ', currentToken);
    } else {
      console.log(
        'No registration token available. Request permission to generate one.',
      );
    }
  })
  .catch((err) => {
    console.log('An error occurred while retrieving token. ', err);
  });

export const requestFCMPermission = async () => {
  // try {
  //   // Request user permission for notifications
  //   const permission = await Notification.requestPermission();
  //
  //   if (permission === 'granted') {
  //     console.log('Notification permission granted.');
  //     // Get FCM token (the public VAPID key is required for web push)
  //     const token = await getToken(messaging, {
  //       vapidKey:
  //         'BCdIujuxqsTQeDDj8wzvVdoCmETA-CZ7ZQKDXvMO_Uch0oaUqrONLUC8kTsAOuTQIYLwLjAlPtjnJr48KAisBbE',
  //     });
  //
  //     if (token) {
  //       console.log('FCM Token:', token);
  //       // Send the token to your server or store it as needed
  //     } else {
  //       console.log('No registration token available.');
  //     }
  //   } else {
  //     console.log('Notification permission not granted.');
  //   }
  // } catch (error) {
  //   console.error('Error while requesting permission or getting token:', error);
  // }
};
