// @flow
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import WithSideBar from '@shared/templates/with-side-bar/WithSideBar';
import { useSelector } from 'react-redux';
import { AppSelector } from '@data/app/app.selector';
import { TextKeys } from '@lib/i18n/configureI18n';
import HighlightedTabs from '@shared/components/tabs/HighlightedTabs';
import { ContentsSelectors } from '@data/contents/Contents.selectors';
import ContentGroup from '@view/discover-page/components/ContentGroup';
import useLoadContents from '@view/discover-page/hooks/useLoadContents';
import { ReactComponent as SortUp } from '@assets/icons/sort_up.svg';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Endpoints from '@view/routes/endpoints';
import ContentGroupSkeleton from '@view/discover-page/components/ContentGroupSkeleton';
import { LoadingState } from '../../models/Loading';

const DiscoverPage: React.FC<unknown> = () => {
  const { t } = useTranslation();
  const profile = useSelector(AppSelector.selectUserInfo);
  const navigate = useNavigate();
  const [tabIndex, setTabIndex] = React.useState(0);
  const isCMSActive = useSelector(AppSelector.selectIsCMSActive);
  const onTabChanged = React.useCallback((index: number) => {
    setTabIndex(index);
  }, []);

  const loadContents = useLoadContents({
    fetchByCategory: true,
  });

  const [sortBy, setSortBy] = React.useState<
    'latest' | 'most-viewed' | 'most-liked'
  >('latest');

  const contents = useSelector((state) =>
    ContentsSelectors.contents.selectPublishedContentsByCategory(
      state,
      sortBy,
      tabIndex === 1,
    ),
  );

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (key) => () => {
    setSortBy(key);
    setAnchorEl(null);
  };

  const renderSortButton = React.useCallback(() => {
    let sortType = 'Newest';
    if (sortBy === 'most-viewed') sortType = 'Most viewed';
    else if (sortBy === 'most-liked') sortType = 'Most helpful';
    return (
      <>
        <div
          className="absolute right-0 flex items-center cursor-pointer top-2"
          onClick={handleClick}
        >
          <SortUp className="w-6 h-6 mr-2" />
          <span className="mr-3 button text-on-secondary">SORT BY</span>
          <span className="h6 text-brand-primary">{sortType}</span>
        </div>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <MenuItem onClick={handleClose('latest')}>
            Newest (recent to oldest)
          </MenuItem>
          <MenuItem onClick={handleClose('most-viewed')}>Most viewed</MenuItem>
          <MenuItem onClick={handleClose('most-liked')}>Most helpful</MenuItem>
        </Menu>
      </>
    );
  }, [anchorEl, open, sortBy]);

  return (
    <WithSideBar>
      <div className="flex flex-col items-center justify-start w-full h-full overflow-auto">
        <div className="w-full bg-white min-h-[149px] flex-col-center">
          <div className="relative flex flex-col items-start h-[87px] justify-evenly w-[90%] max-w-[860px]">
            <h1 className="w-full h1 max-w-[470px]">
              {t(TextKeys.Hello)} {profile.nickName}
            </h1>
            <p className="w-full body1 max-w-[470px]">{t(TextKeys.Welcome)}</p>
            <div
              className={[
                'absolute top-0 right-0 justify-around h-full flex-col-center ',
                isCMSActive ? 'visible' : 'hidden',
              ].join('')}
            >
              <Button
                className="h-10 !text-white w-[200px] rounded-[100px] button !bg-brand-secondary"
                onClick={() => navigate(Endpoints.DISCOVER_MY_PROJECTS)}
              >
                MY PROJECTS
              </Button>
              <Button
                className="h-10 !text-white w-[200px] rounded-[100px] button !bg-brand-primary"
                onClick={() => navigate(Endpoints.DISCOVER_CREATE_SELECTION)}
              >
                CREATE CONTENT
              </Button>
            </div>
          </div>
        </div>
        <div className="relative flex-1 mt-5 w-[90%] max-w-[860px]">
          {renderSortButton()}
          <HighlightedTabs
            tabs={['DISCOVER', 'BOOKMARKS']}
            selectedTab={tabIndex}
            onTabChanged={onTabChanged}
          />

          <div className="mt-2 ">
            {loadContents === LoadingState.LOADING
              ? [3, 2, 3].map((key, index) => (
                  <ContentGroupSkeleton
                    key={`skeleton-${index}`}
                    numOfCards={key}
                  />
                ))
              : Object.keys(contents).map((key) => (
                  <ContentGroup
                    key={key}
                    title={key}
                    contentList={contents[key]}
                  />
                ))}
          </div>
        </div>
      </div>
    </WithSideBar>
  );
};
export default DiscoverPage;
