/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable */
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import ErrorBoundary from '@shared/components/error-boundary';
import { useLocation } from 'react-router-dom';
// eslint-disable-next-line import/no-unresolved
// import PageViewLogger from '@shared/components/firebase/page-view-logger/PageViewLogger';
// eslint-disable-next-line import/no-unresolved
// eslint-disable-next-line import/no-unresolved
// import { Alert } from '@shared/components/alert';
// import { Drawer } from '@mui/material';
import RedirectSite from '../routes/RedirectSite';
import { AppSelector } from '@data/app/app.selector';
import useRegisterNotificationService
  from './hooks/useRegisterNotificationService';
import useTags, { TagsContext } from '@hooks/useTags'; // import { FirestoreProvider, useFirebaseApp } from 'reactfire';
import {
  CloudMessaging,
} from '@shared/components/firebase/cloud-messaging/CloudMessaging';
import AppRoutes from '@view/app-page/components/AppRoutes';
import { requestFCMPermission } from '@lib/configureFirebase';
import useAppDispatch from '@data/useAppDispatch';
import {
  fetchNotificationsCount,
} from '@data/notifications/Notification.thunk';
import { loadConfig, setSingleAppConfig } from '@data/app/app.thunk';
import Endpoints from '@view/routes/endpoints';

//
// export interface AppPageProps {
//
//     mountApp: () => void;
//     notificationToken: string;
//     setNotificationToken: (token: string) => void;
//     getNotification: (
//         notification: Notification,
//         location: Location,
//     ) => notificationAction;
//     alerts: AlertType[];
//     tokenExpiryDate: string;
//     initToken: () => void;
//     fetchNotifications;
//     app: CuratioApp;
// }

/**
 * Main Application Page
 *
 * Load application wide components here.
 *
 * @todo rework auth handling here and use HOC on views that need to be protected instead
 */
const AppPage: React.FC<unknown> = () => {
  const { pathname } = useLocation();
  const isLoggedIn = useSelector(AppSelector.isUserAuthenticated);
  const communitySelected = useSelector(AppSelector.selectIsCommunitySelected);
  const app = useSelector(AppSelector.selectActiveApp);
  const dispatch = useAppDispatch();

  const [configLoaded, setConfigLoaded] = React.useState(false);

  useEffect(() => {
    if (!configLoaded) {
      dispatch(loadConfig()).then(() => {
        setConfigLoaded(true);
      });
      if (process.env.REACT_APP_IS_SINGLE_APP === 'true') {
        dispatch(setSingleAppConfig());
      }
    }
  }, [dispatch, configLoaded]);

  const {
    registerNotificationService,
    isRegistered,
    getNotification,
    isSupported,
  } = useRegisterNotificationService();

  const checkAuth = React.useCallback(
    (Component) => {
      if (isLoggedIn && communitySelected && app) {
        return <Component />;
      }
      if (isLoggedIn && !communitySelected && app) {
        if (pathname === '/portal') {
          return <Component />;
        }
        return RedirectSite('/portal');
      }
      if (process.env.REACT_APP_IS_SINGLE_APP !== 'true')
        return RedirectSite('/app-selection');
      else return RedirectSite(Endpoints.AUTH);
    },
    [isLoggedIn, communitySelected, app, pathname],
  );

  const [tags, loadTags] = useTags();

  useEffect(() => {
    setInterval(() => {
      if (isLoggedIn) {
        dispatch(fetchNotificationsCount()).then(() => {});
      }
    }, 20000);
    // dispatch(fetchNotificationsCount()).then(() => {});
  }, [dispatch, isLoggedIn]);

  // const firebaseApp = useFirebaseApp();
  // const firestoreInstance = getFirestore(firebaseApp);

  return (
    <TagsContext.Provider
      value={{
        tags,
        loadTags,
      }}
    >
      <ErrorBoundary>
        <AppRoutes isLoggedIn={isLoggedIn} app={app} checkAuth={checkAuth} />
        {isSupported && (
          <CloudMessaging
            cb={registerNotificationService}
            load={isRegistered}
            notificationHandler={getNotification}
            loggedIn={isLoggedIn}
          />
        )}
      </ErrorBoundary>
    </TagsContext.Provider>
  );
};

const FirebaseApp = () => {
  useEffect(() => {
    requestFCMPermission()
      .then((r) => console.log(r))
      .catch((e) => console.log(e));
  }, []);

  return (
    // <FirebaseAppProvider firebaseConfig={firebaseConfig}>
    <AppPage />
    // </FirebaseAppProvider>
  );
};

export default FirebaseApp;
