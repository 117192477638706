import * as React from 'react';
import { FunctionComponent, SVGProps } from 'react';
import { ReactComponent as FeedSvg } from '@assets/icons/feed.svg';
import { ReactComponent as Discover } from '@assets/icons/discover.svg';
import { ReactComponent as GroupSvg } from '@assets/icons/group.svg';
import { ReactComponent as MyCircleSvg } from '@assets/icons/my_circle.svg';
import { ReactComponent as ChatSvg } from '@assets/icons/chat.svg';
import { ReactComponent as ScheduledSvg } from '@assets/icons/scheduled.svg';
import { ReactComponent as DraftSvg } from '@assets/icons/draft.svg';
import { ReactComponent as BookmarkSvg } from '@assets/icons/bookmark.svg';
import { ReactComponent as FlaggedSvg } from '@assets/icons/flagged.svg';
import { ReactComponent as GroupRequest } from '@assets/icons/groupRequest.svg';
import { ReactComponent as Alert } from '@assets/icons/alert.svg';
import { ReactComponent as SettingSvg } from '@assets/icons/setting.svg';
import { useSelector } from 'react-redux';
import { AppSelector } from '@data/app/app.selector';
import Endpoints from '@view/routes/endpoints';
import { ChatSelector } from '@data/chat/Chat.selector';

interface Link {
  label: string;
  image?: string;
  icon?: FunctionComponent<
    SVGProps<SVGSVGElement> & { title?: string | undefined }
  >;
  to: string;
  show?: boolean;
  isHighlighted?: boolean;
}

type useLinksReturnType = {
  getLinks: () => Link[];
};

const useLinks = (): useLinksReturnType => {
  const profile = useSelector(AppSelector.selectUserInfo);
  const PVId = useSelector(AppSelector.selectPVId);
  const isCMSActive = useSelector(AppSelector.selectIsCMSActive);
  const isAEDetectionActive = useSelector(
    AppSelector.selectIsAeDetectionActive,
  );
  const isGroupManagementActive = useSelector(
    AppSelector.selectIsGroupManagementActive,
  );
  const filteredConversations = useSelector((state: any) =>
    ChatSelector.conversations.selectFilteredConversations(state, {
      selectedTab: 'Unread',
    }),
  );

  const links = React.useMemo((): Link[] => {
    return [
      // {
      //   label: 'user',
      //   image: profile?.avatar,
      //   to: Endpoints.PROFILE,
      // },
      {
        label: 'Home',
        icon: FeedSvg,
        to: '/',
      },
      {
        label: 'Discover',
        icon: Discover,
        to: Endpoints.DISCOVER,
      },
      {
        label: 'Groups',
        icon: GroupSvg,
        to: Endpoints.GROUPS,
      },
      {
        label: 'My Circle',
        icon: MyCircleSvg,
        to: Endpoints.CIRCLE,
      },
      {
        label: 'Chat',
        icon: ChatSvg,
        to: Endpoints.CHAT,
        isHighlighted: filteredConversations.length > 0,
      },
      {
        label: 'My Scheduled Posts',
        icon: ScheduledSvg,
        to: Endpoints.SCHEDULE_POST,
      },
      {
        label: 'My Drafts',
        icon: DraftSvg,
        to: Endpoints.DRAFTED_POSTS,
      },
      {
        label: 'My Bookmarks',
        icon: BookmarkSvg,
        to: Endpoints.BOOKMARKS,
      },

      {
        label: 'Review Flagged Posts',
        icon: FlaggedSvg,
        to: Endpoints.FLAGGED_POSTS,
      },
      {
        label: 'Review Groups Request',
        icon: GroupRequest,
        to: Endpoints.GROUP_REVIEWS,
        show: isGroupManagementActive,
      },
      {
        label: 'Review Potential AEs',
        icon: Alert,
        to: Endpoints.ADVERSE_EVENTS,
        show: Boolean(PVId) && isAEDetectionActive,
      },
      {
        label: 'Settings',
        icon: SettingSvg,
        to: Endpoints.SETTINGS,
      },
    ];
  }, [
    PVId,
    isAEDetectionActive,
    isGroupManagementActive,
    filteredConversations,
  ]);

  const getLinks = React.useCallback((): Link[] => {
    return links.filter((link) => {
      return link.show !== false;
    });
    // .filter((link) => {
    //   // return !(link.isDesktopSupported && isMobile);
    //   return true;
    // });
  }, [links]);

  return {
    getLinks,
  };
};
export default useLinks;
