import React from 'react';
import { useLocation } from 'react-router-dom';
import useLinks from './hooks/useLinks';
import MobileNavItem from '../nav-item/MobileNavItem';

const MobileMenuLinks: React.FC<unknown> = () => {
  const { getLinks } = useLinks();
  const location = useLocation();

  return (
    <div>
      <ul className="p-0 m-0 list-none">
        {getLinks().map((link) => (
          <MobileNavItem
            isHighlighted={link.isHighlighted}
            to={link.to}
            icon={link.icon}
            label={link.label}
            key={link.label}
            pathname={location.pathname}
          />
        ))}
      </ul>
    </div>
  );
};

export default MobileMenuLinks;
