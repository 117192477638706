import { createAsyncThunk } from '@reduxjs/toolkit';
import FingerPrintUtils from '@utils/fingerprint.utils';
import createAsyncThunkWithAuth from '@data/createAsyncThunkWithAuth';
import axios from 'axios';
import { ApiEndpoint } from '@utils/types';
import { browserName, fullBrowserVersion } from 'react-device-detect';
import { AnyAction } from 'redux';
import { App } from './app.modal';
import config from '../../config';

export const getEmbedrockKey = createAsyncThunkWithAuth<void>(
  'app/getServiceKeys',
  async (_, urlGenerator) => {
    return new Promise((resolve, reject) => {
      axios
        .get(
          urlGenerator(ApiEndpoint.SERVICE_KEY, {
            service: 'embedrock',
          }),
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch(reject);
    });
  },
);

export const getAppInfo = createAsyncThunkWithAuth<{
  appKey: string;
}>('app/getAppInfo', async (args, urlGenerator, dispatch) => {
  return new Promise((resolve, reject) => {
    axios
      .get(urlGenerator(ApiEndpoint.APP_INFO), {
        params: {
          app: args.appKey,
        },
      })
      .then(async (response) => {
        await dispatch(getEmbedrockKey());
        resolve(response.data);
      })
      .catch(reject);
  });
});

export const loginUser = createAsyncThunkWithAuth<{
  email: string;
  password: string;
}>('app/loginUser', async (args, urlGenerator, dispatch, state) => {
  return new Promise((resolve, reject) => {
    FingerPrintUtils.fingerPrintGenerator()
      .then((deviceId) => {
        const br = browserName.replace(/ /g, '');
        axios
          .post(urlGenerator(ApiEndpoint.LOGIN), {
            email: args.email,
            password: args.password,
            device: deviceId,
            client_id: `${config.platform}-${config.version}-${br}-${fullBrowserVersion}`,
          })
          .then(async (response) => {
            const appData = state?.app?.selectedApp;
            const appInfo = (await dispatch(
              getAppInfo({ appKey: appData?.key }),
            )) as unknown as AnyAction;
            resolve({
              loginData: response.data,
              appInfo: appInfo.payload,
            });
          })
          .catch(reject);
      })
      .catch(reject);
  });
});

export const logoutUser = createAsyncThunkWithAuth<void>(
  'app/logoutUser',
  async (_, urlGenerator) => {
    return new Promise((resolve, reject) => {
      axios
        .post(urlGenerator(ApiEndpoint.LOGOUT))
        .then(() => {
          resolve(null);
        })
        .catch(reject);
    });
  },
);

export const activateCommunity = createAsyncThunk(
  'app/activateCommunity',
  async (communityKey: string) => {
    return {
      communityKey,
    };
  },
);

export const getApps = createAsyncThunk('app/getApps', async () => {
  return new Promise<any>((resolve, reject) => {
    fetch(process.env.REACT_APP_GET_APPS_ENDPOINTS || '')
      .then((response) => {
        if (process.env.REACT_APP_ENVIRONMENT === 'production') {
          return response.text();
        } else return response.json();
      })
      .then((response) => {
        if (process.env.REACT_APP_ENVIRONMENT === 'production') {
          axios
            .post(`${process.env.REACT_APP_EXPRESS_API}/decrypt`, {
              encryptedData: response,
            })
            .then((response2) => {
              const decryptedApps =
                JSON.parse(response2?.data?.decrypted_message) || [];
              resolve(decryptedApps);
            });
        } else resolve(response);
      })
      .catch(reject);
  });
});

export const setActiveApp = createAsyncThunk(
  'app/setActiveApp',
  async (appKey: string, ThunkApi) => {
    return new Promise<any>((resolve, reject) => {
      const activeApp: App | undefined = (ThunkApi.getState() as any).app.apps
        .entities[appKey];
      if (!activeApp) {
        reject(new Error('App not found'));
        return;
      }
      axios
        .get(`https://webdev.curatio.me/encrypt`, {
          params: {
            app_name: activeApp.key,
            app_version: activeApp.version,
          },
        })
        .then((response) => {
          axios
            .post(`${activeApp?.apiUrl}/${ApiEndpoint.API_INIT}`, {
              app_name: activeApp.key,
              encrypted_message: response.data.encrypted_message,
              version: activeApp.version,
              platform: config.platform,
            })
            .then((response2) => {
              return resolve({
                activeApp,
                authData: response2.data,
              });
            })
            .catch(reject);
        })
        .catch(reject);
    });
  },
);

export const registerNotificationToken = createAsyncThunkWithAuth<{
  notificationToken: string;
}>('app/notification/registerToken', async (args, urlGenerator) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        urlGenerator(ApiEndpoint.SUBSCRIBE_USER, {
          user: args.userId,
        }),
        {
          notification_token: args.notificationToken,
        },
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch(reject);
  });
});

export const setSingleAppConfig = createAsyncThunk(
  'app/setSingleAppConfig',
  async () => {
    const activeApp = {
      key: process.env.REACT_APP_APP_KEY,
      version: process.env.REACT_APP_APP_VERSION,
      apiUrl: process.env.REACT_APP_APP_API_URL,
      name: process.env.REACT_APP_APP_NAME,
      imgUrl: process.env.REACT_APP_APP_IMG_URL,
    };

    return new Promise<any>((resolve, reject) => {
      axios
        .get(`https://webdev.curatio.me/encrypt`, {
          params: {
            app_name: activeApp.key,
            app_version: activeApp.version,
          },
        })
        .then((response) => {
          axios
            .post(`${activeApp?.apiUrl}/${ApiEndpoint.API_INIT}`, {
              app_name: activeApp.key,
              encrypted_message: response.data.encrypted_message,
              version: activeApp.version,
              platform: config.platform,
            })
            .then((response2) => {
              return resolve({
                activeApp,
                authData: response2.data,
              });
            })
            .catch(reject);
        })
        .catch(reject);
    });
  },
);

export const loadConfig = createAsyncThunk('app/loadConfig', async (_) => {
  return {
    isGroupManagementEnabled:
      process.env.REACT_APP_IS_GROUP_MANAGEMENT_ENABLED === 'true',
    isAeDetectionEnabled:
      process.env.REACT_APP_IS_AE_DITECTION_ENABLED === 'true',
    isCMSEnabled: process.env.REACT_APP_IS_CMS_ENABLED === 'true',
  };
});
