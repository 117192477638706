import { createSelector } from '@reduxjs/toolkit';
import { App } from '@data/app/app.modal';
import { appAdapter } from './app.reducer';

export const AppSelector = {
  selectIsCMSActive: createSelector(
    (state) => state?.app,
    (app) => {
      return app?.isCMSEnabled;
    },
  ),
  selectIsGroupManagementActive: createSelector(
    (state) => state?.app,
    (app) => {
      return app?.isGroupManagementEnabled;
    },
  ),
  selectIsAeDetectionActive: createSelector(
    (state) => state?.app,
    (app) => {
      return app?.isAeDetectionEnabled;
    },
  ),
  isUserAuthenticated: createSelector(
    (state) => state?.app,
    (app) => {
      return Boolean(app?.user);
    },
  ),
  selectAvailableCommunities: createSelector(
    (state) => state?.app,
    (app) => {
      return Object.fromEntries(
        app?.conditions.map((condition) => [
          condition.key,
          {
            logo: condition.badgeImage,
            displayName: condition.title,
          },
        ]),
      );
    },
  ),
  selectUserId: createSelector(
    (state) => state?.app,
    (app) => {
      return app?.user?.id;
    },
  ),
  selectDeviceId: createSelector(
    (state) => state?.app,
    (app) => {
      return app?.auth.deviceId;
    },
  ),
  selectActiveCommunity: createSelector(
    (state) => state?.app,
    (app) => {
      return app?.activeCommunity;
    },
  ),
  selectIsCommunitySelected: createSelector(
    (state) => state?.app,
    (app) => {
      return Boolean(app?.activeCommunity);
    },
  ),
  selectUserInfo: createSelector(
    (state) => state?.app,
    (app) => {
      return app?.user;
    },
  ),
  selectActiveApp: createSelector(
    (state: any) => state?.app,
    (app: any) => {
      if (!app?.activeApp) {
        return {
          imgUrl: '',
        };
      }
      return app?.selectedApp;
    },
  ),
  selectServiceKey: createSelector(
    (state) => state?.app,
    (app) => {
      return app?.auth.embedrockKey;
    },
  ),
  selectUserEmail: createSelector(
    (state) => state?.app,
    (app) => {
      return app?.email;
    },
  ),
  selectPVId: createSelector(
    (state) => state?.app,
    (app) => {
      return app?.appInfo?.pv_flow_id;
    },
  ),
  selectNotificationToken: createSelector(
    (state) => state?.app,
    (app) => {
      return app?.auth?.notificationToken;
    },
  ),
  selectAuthToken: createSelector(
    (state) => state?.app,
    (app) => {
      return app?.auth?.authToken;
    },
  ),
  selectAppToken: createSelector(
    (state) => state?.app,
    (app) => {
      return app?.auth?.appToken;
    },
  ),
  selectApps: createSelector(
    (state) => state?.app,
    (app): Record<string, App> => {
      return appAdapter.getSelectors().selectEntities(app.apps) as Record<
        string,
        App
      >;
    },
  ),
  selectRecentApps: createSelector(
    (state) => state?.app,
    (app) => {
      return app?.recentApps || [];
    },
  ),
  app: {
    ...appAdapter.getSelectors(),
  },
};
